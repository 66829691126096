<template>
	 <b-modal ref="modal" hide-footer size="xl">
        <template v-slot:modal-title>
       		{{ $t("payment.solde_invoice_cc") }}
        </template>

       	{{ $t("payment.selectionner_facture_to_solde") }} {{ current_account }}
        <CustomTable
			id_table="invoice_to_solde"
			ref="invoice_to_solde"
			:items="unpaid"
			primaryKey="invoice_id"
			:hide_if_empty="true"
        />
    </b-modal>
</template>

<script type="text/javascript">
    import Tiers from "@/mixins/Tiers.js"
    import Navigation from "@/mixins/Navigation.js"
    import Payment from "@/mixins/Payment.js"
    import { EventBus } from "EventBus"

	export default {
		name: 'ModalSoldeInvoiceCC',
		mixins: [Tiers, Navigation, Payment],
		data () {
			return {
				tiers_id: null,
				accountingplan_id: null,
				unpaid: [],
				current_account: 0,
				events_tab: {
                    'TableAction::goToSoldeInvoice': this.soldeInvoiceCC
                }
			}
		},

		methods: {
			async openModal(tiers_id, accountingplan_id) {
				this.$refs.modal.show()
				this.tiers_id = tiers_id
				this.accountingplan_id = accountingplan_id

				const invoices = await this.loadAllInvoicesByTiers(tiers_id)
				this.unpaid = invoices.filter(invoice => invoice.invoice_balance > 0 && invoice.invoice_accountingplan == accountingplan_id)

				let current_account = await this.loadCurrentAccount(tiers_id, true)
				current_account = current_account[accountingplan_id]
				if(current_account) {
					this.current_account = await this.priceFormat(current_account.balance / 100, current_account.currency, true)
				}
			},

			async soldeInvoiceCC(invoices) {
				const ids = invoices.map(invoice => invoice.invoice_id)
				await this.soldeInvoiceFromCC(ids, this.accountingplan_id)
				EventBus.$emit("TableAction::stopSpin")
				this.$refs.modal.hide()
				this.successToast("toast.info_save_succes")
			}
		},

		components: {
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
		}
	}

</script>